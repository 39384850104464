/* eslint-disable */
<template>
  <v-app style="background:white">
    <section class="mt-10">
      <v-container
        fluid
        style="
          background-image: url(https://picsum.photos/1920/1080?random);
          min-height: 500px;
        "
      >
        <v-row justify="center" class="mt-16">
          <h1 class="display-4 mt-16" style="font-weight: bolder">
            ONLINE COURSE xxx  
          </h1> 
        </v-row>
      </v-container>
      <v-container fluid style="background-color: #ffc107" class="py-0">
        <v-container class="py-0">
          <v-tabs color="red" background-color="transparent">
            <v-tab>Description</v-tab>
            <v-tab>Lessons</v-tab>
            <v-tab>Reviews</v-tab>
          </v-tabs>
          <v-tabs-slider color="red"></v-tabs-slider>
        </v-container>
      </v-container>
    </section>
    <section class="mt-10">
      <v-container>
        <v-row>
          <v-col cols="8">
            <h1 class="text-h2" style="font-weight: bolder">Description {{courseId}}</h1>
            <span>
              Per consequat adolescens ex, cu nibh commune temporibus vim, ad
              sumo viris eloquentiam sed. Mea appareat omittantur eloquentiam
              ad, nam ei quas oportere democritum. Prima causae admodum id est,
              ei timeam inimicus sed. Sit an meis aliquam, cetero inermis vel
              ut. An sit illum euismod facilisis, tamquam vulputate pertinacia
              eum at.
            </span>
            <h1 class="text-h3 mt-6" style="font-weight: bold">
              What will you learn
            </h1>
            <v-row>
              <v-icon x-large right>mdi-checkbox-marked-circle </v-icon>
              <v-col cols="11">
                <h3 style="font-weight: light">
                  Suas summo id sed erat erant oporteat
                </h3>
                <span>
                  Ut unum diceret eos, mel cu velit principes, ut quo inani
                  dolorem mediocritatem. Mea in justo posidonium necessitatibus.
                </span>
              </v-col>
            </v-row>
            <v-row>
              <v-icon x-large right>mdi-checkbox-marked-circle </v-icon>
              <v-col cols="11">
                <h3 style="font-weight: light">
                  Illud singulis indoctum ad sed
                </h3>
                <span>
                  Ut unum diceret eos, mel cu velit principes, ut quo inani
                  dolorem mediocritatem. Mea in justo posidonium necessitatibus.
                </span>
              </v-col>
            </v-row>
            <v-row>
              <v-icon x-large right>mdi-checkbox-marked-circle </v-icon>
              <v-col cols="11">
                <h3 style="font-weight: light">
                  Alterum bonorum mentitum an mel
                </h3>
                <span>
                  Ut unum diceret eos, mel cu velit principes, ut quo inani
                  dolorem mediocritatem. Mea in justo posidonium necessitatibus.
                </span>
              </v-col>
            </v-row>
            <p class="mt-16 mb-8">
              Mea appareat omittantur eloquentiam ad, nam ei quas oportere
              democritum. Prima causae admodum id est, ei timeam inimicus sed.
              Sit an meis aliquam, cetero inermis vel ut. An sit illum euismod
              facilisis, tamquam vulputate pertinacia eum at.
            </p>
            <v-row>
              <v-col cols="6" class="ml-3">
                <v-row>
                  <v-icon x-small>mdi-circle-slice-8</v-icon>
                  <span>Dolorem mediocritatem</span>
                </v-row>
                <v-row>
                  <v-icon x-small>mdi-circle-slice-8</v-icon>
                  <span>Mea appareat</span>
                </v-row>
                <v-row>
                  <v-icon x-small>mdi-circle-slice-8</v-icon>
                  <span>Prima causae</span>
                </v-row>
                <v-row>
                  <v-icon x-small>mdi-circle-slice-8</v-icon>
                  <span>Singulis indoctum</span>
                </v-row>
              </v-col>
              <v-col cols="5">
                <v-row>
                  <v-icon x-small>mdi-circle-slice-8</v-icon>
                  <span>Timeam inimicus</span>
                </v-row>
                <v-row>
                  <v-icon x-small>mdi-circle-slice-8</v-icon>
                  <span>Oportere democritum </span>
                </v-row>
                <v-row>
                  <v-icon x-small>mdi-circle-slice-8</v-icon>
                  <span>Cetero inermis </span>
                </v-row>
                <v-row>
                  <v-icon x-small>mdi-circle-slice-8</v-icon>
                  <span>Pertinacia eum </span>
                </v-row>
              </v-col>
            </v-row>

            <h1 class="text-h2 mt-10" style="font-weight: bolder">Lessons</h1>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus
              imperdiet, nulla et dictum interdum, nisi lorem egestas odio,
              vitae scelerisque enim ligula venenatis dolor. Maecenas nisl est,
              ultrices nec congue eget, auctor vitae massa. Fusce luctus
              vestibulum augue ut aliquet. Mauris ante ligula, facilisis sed
              ornare eu, lobortis in odio. Praesent convallis urna a lacus
              interdum ut hendrerit risus congue. Nunc sagittis dictum nisi, sed
              ullamcorper ipsum dignissim ac. In at libero sed nunc venenatis
              imperdiet sed ornare turpis. Donec vitae dui eget tellus gravida
              venenatis. Integer fringilla congue eros non fermentum. Sed
              dapibus pulvinar nibh tempor porta. Cras ac leo purus. Mauris quis
              diam velit. Lorem ipsum dolor sit amet, consectetur adipiscing
              elit. Phasellus imperdiet, nulla et dictum interdum, nisi lorem
              egestas odio, vitae scelerisque enim ligula venenatis dolor.
              Maecenas nisl est, ultrices nec congue eget, auctor vitae massa.
              Fusce luctus vestibulum augue ut aliquet. Mauris ante ligula,
              facilisis sed ornare eu, lobortis in odio. Praesent convallis urna
              a lacus interdum ut hendrerit risus congue. Nunc sagittis dictum
              nisi, sed ullamcorper ipsum dignissim ac. In at libero sed nunc
              venenatis imperdiet sed ornare turpis. Donec vitae dui eget tellus
              gravida venenatis. Integer fringilla congue eros non fermentum.
              Sed dapibus pulvinar nibh tempor porta. Cras ac leo purus. Mauris
              quis diam velit. Lorem ipsum dolor sit amet, consectetur
              adipiscing elit. Phasellus imperdiet, nulla et dictum interdum,
              nisi lorem egestas odio, vitae scelerisque enim ligula venenatis
              dolor. Maecenas nisl est, ultrices nec congue eget, auctor vitae
              massa. Fusce luctus vestibulum augue ut aliquet. Mauris ante
              ligula, facilisis sed ornare eu, lobortis in odio. Praesent
              convallis urna a lacus interdum ut hendrerit risus congue. Nunc
              sagittis dictum nisi, sed ullamcorper ipsum dignissim ac. In at
              libero sed nunc venenatis imperdiet sed ornare turpis. Donec vitae
              dui eget tellus gravida venenatis. Integer fringilla congue eros
              non fermentum. Sed dapibus pulvinar nibh tempor porta. Cras ac leo
              purus. Mauris quis diam velit. Lorem ipsum dolor sit amet,
              consectetur adipiscing elit. Phasellus imperdiet, nulla et dictum
              interdum, nisi lorem egestas odio, vitae scelerisque enim ligula
              venenatis dolor. Maecenas nisl est, ultrices nec congue eget,
              auctor vitae massa. Fusce luctus vestibulum augue ut aliquet.
              Mauris ante ligula, facilisis sed ornare eu, lobortis in odio.
              Praesent convallis urna a lacus interdum ut hendrerit risus
              congue. Nunc sagittis dictum nisi, sed ullamcorper ipsum dignissim
              ac. In at libero sed nunc venenatis imperdiet sed ornare turpis.
            </p>
          </v-col>
          <v-col cols="4">
            <v-card class="stick" style="border: 1px solid #ededed;background-color: #f8f8f8;">
              <v-img src="/course-test.jpg"></v-img>
              <v-card-title class="display-3" style="font-weight: bolder"
                >$29</v-card-title
              >
              <v-col cols="10" offset="1">
                <v-btn color="primary" width="100%">Purchase</v-btn>
              </v-col>
              <v-col cols="10" offset="1">
                <v-btn outlined color="primary" width="100%">
                  <v-icon>mdi-heart</v-icon>
                  Add to wishlist</v-btn
                >
              </v-col>
              <v-card-title class="display-2" style="font-weight: bolder"
                >What's includes</v-card-title
              >
              <v-card-text class="ml-3">
                <v-row>
                  <v-icon x-small>mdi-cellphone</v-icon>
                  <span>Mobile support</span>
                </v-row>
                <v-row>
                  <v-icon x-small>mdi-archive</v-icon>
                  <span>Lesson archive </span>
                </v-row>
                <v-row>
                  <v-icon x-small>mdi-forum</v-icon>
                  <span>Tutor chat </span>
                </v-row>
                <v-row>
                  <v-icon x-small>mdi-certificate</v-icon>
                  <span>Course certificate </span>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </v-app>
</template>
<script>
const fb = require("../../firebaseConfig.js");
export default {
  props: {
     courseId: {
        type: String,
        required: true,
      },
  },
  data: () => ({
    item: null,
  }),
  created() {
    console.log(this.courseId);
    // fb.db
    //   .collection("asuulga")
    //   .doc(this.asuulgaId)
    //   .get()
    //   .then((itemDoc) => {
    //     this.item = itemDoc.data();
    //     this.item.ref = itemDoc.ref;
    //     this.item.id = itemDoc.id;
    //   });
  },
  methods: {
     
  },
};
</script>
<style>
.heading-2 {
  font-size: 2.125rem !important;
  font-weight: 400;
  line-height: 2.5rem;
  letter-spacing: 0.0073529412em !important;
  font-family: "Roboto", sans-serif !important;
}

.heading-1 {
  font-size: 2.125rem !important;
  line-height: 2.5rem;
  letter-spacing: 0.00735294em !important;
  font-family: "Roboto", sans-serif;
}
.body-2 {
  font-size: 0.875rem !important;
  font-weight: 400;
  letter-spacing: 0.0178571429em !important;
  line-height: 1.25rem;
  font-family: "Roboto", sans-serif !important;
}
.body-3 {
  font-size: 2.125rem !important;
  font-weight: bold;
  line-height: 2.5rem;
  letter-spacing: 0.0073529412em !important;
  font-family: "Roboto", sans-serif !important;
}
</style>

<script>
export default {
 
      props: {
      courseId: {
        type: String,
        required: false,
      },
     
    },
  data: () => ({
    item: {
      color: "#952175",
      src: "https://cdn.vuetifyjs.com/images/cards/halcyon.png",
      title: "Halcyon Days",
      artist: "Ellie Goulding",
    },
    rating: 3,
  }),
  methods: {
  
  },
};
</script>